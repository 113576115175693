/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import createSentryMiddleware from 'redux-sentry-middleware';
import { sentryOptions } from './utils/sentryOptions';
import * as env from './env';
import { isSupportedBrowser } from '.';
import BaseAPI from "./utils/API/BaseAPI";

fetch('/sentryDSN').then(res => res.text()).then(dsn => {
	Sentry.init({
		dsn,
		environment: env.getEnv(),
		release: env.getVersion(),
		enabled: env.isLive() && isSupportedBrowser,
		autoSessionTracking: true,
		integrations: [
			new Integrations.BrowserTracing(),
		],
		tracesSampleRate: .5,
		ignoreErrors: [
			"502 Bad Gateway",
			"Lock wait timeout exceeded",
			"Non-Error promise rejection captured with value: Timeout",
			'503 Service Temporarily Unavailable',
			'Generator is already running',
			'ChunkLoadError',
			'replaceAll is not a function',
			'Non-Error exception captured',
			'Non-Error promise rejection captured',
			'Failed to fetch',
			'_leaflet_pos'
		],
		beforeSend(event) {
			// filter out UnhandledRejection errors that have no information
			if (event !== undefined && event.exception !== undefined && event.exception.values !== undefined
				&& event?.exception?.values?.length === 1) {
				const e = event.exception.values[0];
				if(e.value.match(/Missing required parameters: sitekey/gms)) return null;
				if(e.value.match(/Loading .* chunk [0-9]* failed.*/gms))
				{
					// Caching issue. New version available.
					window.location.reload();
					return null;
				}
				if (e.type === 'UnhandledRejection' && e.value === 'Non-Error promise rejection captured with value: ') {
					return null;
				}
			}
			return event;
		}
	});
});

const sagaMiddleware = createSagaMiddleware();
const sentryMiddleware = createSentryMiddleware(Sentry, sentryOptions);

let store;

export const getStore = () => (store);

export default function configureStore(initialState = {}) {
	// Create the store with two middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	const middlewares = [sentryMiddleware, sagaMiddleware];

	const enhancers = [applyMiddleware(...middlewares)];

	// If Redux DevTools Extension is installed use it, otherwise use Redux compose
	/* eslint-disable no-underscore-dangle, indent */
	const composeEnhancers =
		!env.isLive() &&
		typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Prevent recomputing reducers for `replaceReducer`
				shouldHotReload: false,
			})
			: compose;
	/* eslint-enable */

	store = createStore(
		createReducer(),
		initialState,
		composeEnhancers(...enhancers),
	);

	const appSaga = require('./redux/sagas/app.saga').default;
	sagaMiddleware.run(appSaga);

	// Extensions
	store.runSaga = sagaMiddleware.run;
	store.injectedReducers = {}; // Reducer registry
	store.injectedSagas = {}; // Saga registry


	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			store.replaceReducer(createReducer(store.injectedReducers));
		});
	}

	return store;
}
